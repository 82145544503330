<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-tabs>
              <b-tab
                title="Hội đồng nghiệm thu đề tài"
                active
              >
                <ReseachReviewCommittees :data-form="dataForm" />
              </b-tab>
              <b-tab title="Phiếu nhận xem nghiệm thu đề tài">
                <ResearchReviewCriteria :data-form="dataForm"  />
              </b-tab>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCol, BFormSelect, BOverlay, BPagination, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ReseachReviewCommittees from '@/views/research/ReseachReviewCommittees.vue'
import ResearchReviewCriteria from '@/views/research/ResearchReviewCriteria.vue'

export default {
  name: 'FormReseachReviewCommittees',
  components: {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BFormSelect,
    BOverlay,
    BPagination,
    BTabs,
    BTab,
    VueGoodTable,
    ReseachReviewCommittees,
    ResearchReviewCriteria,
  },
  props: {
    curriculumVitaeScholarships: {
      type: Array,
      default: () => [],
    },
    curriculumVitaeAwards: {
      type: Array,
      default: () => [],
    },
    curriculumVitaeDisciplines: {
      type: Array,
      default: () => [],
    },
    dataForm: {
      type: Object,
      default: () => ({
        id: null,
        rowNum: null,
        name: '',
        status: 0,
        orderNo: 1,
        code: '',
        orgId: null,
        fieldId: null,
        categoryId: null,
        levelId: null,
        cost: null,
        startDate: null,
        endDate: null,
      }),
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
