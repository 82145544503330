<template>
  <validation-observer ref="saveFormRef" #default="{ invalid }">
    <b-overlay :show="isLoading" rounded="sm">
      <b-card no-body>
        <div class="m-2">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" width="5%" class="text-center">STT</th>
                <th scope="col" width="65%">Tiêu chí đánh giá</th>
                <th scope="col" width="15%" class="text-center">Điểm tối đa</th>
                <th scope="col" width="15%" class="text-center">Điểm đánh giá</th>
              </tr>
            </thead>
            <tbody v-if="dataList[0] && dataList[0].criterias && dataList[1] && dataList[1].criterias && dataList[2] && dataList[2].criterias && dataList[3] && dataList[3].criterias">
              <tr>
                <th scope="row" class="text-center">1</th>
                <td><b>{{ dataList[0] && dataList[0].groupName ? dataList[0].groupName : '' }}</b></td>
                <td class="text-center"><b>{{ dataList[0] && dataList[0].groupMaxPoint }}</b></td>
                <td class="text-center" v-if="dataList[0]">
                  <b-form-input v-model="dataList[0].groupPoint" type="number" name="value" :disabled="true" />
                </td>
              </tr>
              <tr v-for="(criteria, index) in dataList[0].criterias" :key="'1' + index">
                <td scope="row" class="text-center">1.{{ index + 1 }}</td>
                <td>{{ criteria.criteriaName }}
                  <b-form-textarea id="description" v-model="criteria.content" name="description"
                    placeholder="" rows="3" max-rows="5" />
                </td>
                <td class="text-center">{{ criteria.criteriaMaxPoint }}</td>
                <td class="text-center">
                  <validation-provider #default="{ errors }" name="Nhập điểm"
                    :rules="'required|max-value:' + criteria.criteriaMaxPoint">
                    <b-form-input v-model="criteria.point" type="number" name="value" placeholder="Nhập điểm"
                      @keydown="validateKeyDown" @input="pointGroupChanged(0, index)" />
                    <b-form-invalid-feedback :state="getElementState(errors)">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </td>
              </tr>
              <tr>
                <th scope="row" class="text-center">2</th>
                <td><b>{{ dataList[1] && dataList[1].groupName ? dataList[1].groupName : '' }}</b></td>
                <td class="text-center"><b>{{ dataList[1] && dataList[1].groupMaxPoint }}</b></td>
                <td class="text-center" v-if="dataList[1]">
                  <b-form-input v-model="dataList[1].groupPoint" type="number" name="value" :disabled="true" />
                </td>
              </tr>
              <tr v-for="(criteria, index) in dataList[1].criterias" :key="'2' + index">
                <td scope="row" class="text-center">2.{{ index + 1 }}</td>
                <td>{{ criteria.criteriaName }}
                  <b-form-textarea id="description" v-model="criteria.content" name="description"
                    placeholder="" rows="3" max-rows="5" />
                </td>
                <td class="text-center">{{ criteria.criteriaMaxPoint }}</td>
                <td class="text-center">
                  <validation-provider #default="{ errors }" name="Nhập điểm"
                    :rules="'required|max-value:' + criteria.criteriaMaxPoint">
                    <b-form-input v-model="criteria.point" type="number" name="value" placeholder="Nhập điểm"
                      @keydown="validateKeyDown" @input="pointGroupChanged(1, index)" />
                    <b-form-invalid-feedback :state="getElementState(errors)">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </td>
              </tr>
              <tr>
                <th scope="row" class="text-center">3</th>
                <td><b>{{ dataList[2] && dataList[2].groupName ? dataList[1].groupName : '' }}</b></td>
                <td class="text-center"><b>{{ dataList[2] && dataList[2].groupMaxPoint }}</b></td>
                <td v-if="dataList[2]">
                  <b-form-input v-model="dataList[2].groupPoint" type="number" name="value" :disabled="true" />
                </td>
              </tr>
              <tr v-for="(criteria, index) in dataList[2].criterias" :key="'3' + index">
                <td scope="row" class="text-center">3.{{ index + 1 }}</td>
                <td>{{ criteria.criteriaName }}
                  <b-form-textarea id="description" v-model="criteria.content" name="description"
                    placeholder="" rows="3" max-rows="5" />
                </td>
                <td class="text-center">{{ criteria.criteriaMaxPoint }}</td>
                <td class="text-center">
                  <validation-provider #default="{ errors }" name="Nhập điểm"
                    :rules="'required|max-value:' + criteria.criteriaMaxPoint">
                    <b-form-input v-model="criteria.point" type="number" name="value" placeholder="Nhập điểm"
                      @keydown="validateKeyDown" @input="pointGroupChanged(2, index)" />
                    <b-form-invalid-feedback :state="getElementState(errors)">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </td>
              </tr>
              <tr>
                <th scope="row" class="text-center">4</th>
                <td><b>{{ dataList[3] && dataList[3].groupName ? dataList[1].groupName : '' }}</b></td>
                <td class="text-center"><b>{{ dataList[3] && dataList[3].groupMaxPoint }}</b></td>
                <td class="text-center" v-if="dataList[3]">
                  <b-form-input v-model="dataList[3].groupPoint" type="number" name="value" :disabled="true" />
                </td>
              </tr>
              <tr v-for="(criteria, index) in dataList[3].criterias" :key="'4' + index">
                <td scope="row" class="text-center">4.{{ index + 1 }}</td>
                <td>{{ criteria.criteriaName }}
                  <b-form-textarea id="description" v-model="criteria.content" name="description"
                    placeholder="" rows="3" max-rows="5" />
                </td>
                <td class="text-center">{{ criteria.criteriaMaxPoint }}</td>
                <td class="text-center">
                  <validation-provider #default="{ errors }" name="Nhập điểm"
                    :rules="'required|max-value:' + criteria.criteriaMaxPoint">
                    <b-form-input v-model="criteria.point" type="number" name="value" placeholder="Nhập điểm"
                      @keydown="validateKeyDown" @input="pointGroupChanged(3, index)" />
                    <b-form-invalid-feedback :state="getElementState(errors)">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="dataList[4] && dataList[4].criterias">
            <div class="row my-1" v-for="(criteria, index) in dataList[4].criterias" :key="'5' + index">
              <div scope="row" class="text-center" style="width: 105px;"><b>{{ 5 + index }}</b></div>
              <div style="width: 90.5%"><b class="ml-1" v-html="criteria.criteriaName"></b></div>
              <div class="row col-12 d-flex justify-content-center">
                <b-form-textarea v-if="index !== (dataList[4].criterias.length - 1)" id="description"
                  v-model="criteria.content" name="description" placeholder="" rows="3" max-rows="5"
                  style="margin-left: 120px; margin-right: 120px;" />
                <b-form-radio-group v-else v-model="rankValuePreview"
                  :options="dataList[dataList.length - 1].criterias[dataList[dataList.length - 1].criterias.length - 1].ranks"
                  class="custom-control-primary radio-group-custom mt-3" disabled />
              </div>
            </div>
          </div>
        </div>
        <div class="row col-12 d-flex justify-content-center">
          <div style="width: 80%;">
            <b>Chú thích:</b>
            <p>Điểm đánh giá là điểm tổng cộng được xếp loại như sau:</p>
            <p>1. Đề tài xếp loại xuất sắc có tổng số điểm từ 35 điểm trở lên, trong đó, phần giá trị khoa học đạt từ 18 điểm trở lên và phần giá trị ứng dụng, giá trị thực tiễn kết quả của đề tài đạt từ 8 điểm trở lên.</p>
            <p>2. Đề tài xếp loại đạt có tổng số điểm đạt từ 20 đến dưới 35 điểm</p>
            <p>3. Đề tài xếp loại không đạt có tổng số điểm đạt dưới 20  điểm</p>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-end">
          <b-button v-if="dataList[0] && dataList[0].updateFlag === 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" :disabled="invalid"
            @click="onSave(0)">
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu nháp
            </span>
          </b-button>
          <b-button v-if="dataList[0] && dataList[0].updateFlag === 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" :disabled="invalid"
            @click="onSave(1)">
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Chốt
            </span>
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary"
            @click="$bvModal.hide('formReseachReviewCommitteesAndReviewResults')">
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </b-card>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BButton, BCard, BCol, BFormSelect, BOverlay, BPagination, BRow, BFormTextarea, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
  name: 'FormEmployeeResearch1',
  components: {
    VueGoodTable,
    BButton,
    BCard,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    BFormRadioGroup
  },
  directives: {
    Ripple,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({
        id: null,
        rowNum: null,
        name: '',
        status: 0,
        orderNo: 1,
        code: '',
        orgId: null,
        fieldId: null,
        categoryId: null,
        levelId: null,
        cost: null,
        startDate: null,
        endDate: null,
      }),
    },
  },
  data() {
    return {
      isLoading: false,
      rankValuePreview: null,
      required,
    }
  },
  computed: {
    ...mapGetters({
      dataList: 'research/researchReviewResults',
    }),
  },
  async created() {
    // init data FROM API
    await this.getInitData()
  },
  methods: {
    // handle even
    ...mapActions({
      getData: 'research/getResearchReviewResults',
      saveData: 'research/saveResearchReviewResults',
    }),

    validateKeyDown(event) {
      // Lấy giá trị hiện tại của input
      const value = event.target.value;

      // Cho phép các phím điều khiển như Backspace, Delete, Arrow keys
      const controlKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Tab'];

      // Kiểm tra nếu phím được nhấn là phím điều khiển
      if (controlKeys.includes(event.key)) {
        return;
      }

      // Cho phép các phím số và dấu chấm
      const numberAndDotKeys = /[0-9,]/;

      // Kiểm tra nếu phím không phải là số hoặc dấu chấm
      if (!numberAndDotKeys.test(event.key)) {
        event.preventDefault();
        return;
      }

      // Ngăn không cho nhập nhiều hơn một dấu chấm
      if (event.key === ',' && value.includes(',')) {
        event.preventDefault();
        return;
      }
    },

    formatNumber(numberInput) {
      // Ép kiểu biến và định dạng với 2 chữ số sau dấu thập phân
      let num = parseFloat(numberInput);
      if (!isNaN(num)) {
        this.formattedNumber = num.toFixed(2);
      } else {
        this.formattedNumber = '';
      }
      return this.formattedNumber
    },

    pointGroupChanged(indexGroup, indexCriteria) {
      let total = 0
      this.dataList[indexGroup].criterias.forEach((item) => {
        total += Number(item.point)
      })
      this.dataList[indexGroup].groupPoint = this.formatNumber(total)

      let totalPointGroups = 0
      this.dataList.forEach((group) => {
        if (group.groupPoint) {
          totalPointGroups += Number(group.groupPoint)
        }
      })
      if (totalPointGroups >= 35 && this.dataList[1].groupPoint > 18 && this.dataList[2].groupPoint > 8) {
        this.rankValuePreview = 1
      } else if (totalPointGroups >= 20 && totalPointGroups < 35) {
        this.rankValuePreview = 2
      } else if (totalPointGroups >= 0 && totalPointGroups < 20) {
        this.rankValuePreview = 3
      }
    },

    async getInitData() {
      this.isLoading = true
      try {
        const params = {
          researchId: this.dataForm.id ? this.dataForm.id : null,
        }
        await this.getData(params)
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }
    },

    async onSave(type) {
      this.dataList[0].updateFlag = type
      if(this.dataList[this.dataList.length - 1].criterias[this.dataList[this.dataList.length - 1].criterias.length - 2].content === '') {
        this.showToast('Mục 7 bắt buộc phải ghi', 'CheckIcon', 'warning', 3000)
        return
      }
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.saveData({ params: this.dataList });
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },

    getElementState(errors) {
      return errors.length > 0 ? false : null
    },

    showToast(title, icon, variant, autoHideDelay = 3000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
  },
  watch: {
    'dataList': async function (val) {
      let lastArrayElementIndex = this.dataList.length - 1
      let lastCriteriaIndexInLastArrayElement = this.dataList[lastArrayElementIndex].criterias.length - 1
      this.rankValuePreview = this.dataList[lastArrayElementIndex].criterias[lastCriteriaIndexInLastArrayElement].rankValue
    },
  },
}
</script>

<style scoped></style>
