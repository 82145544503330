<template>
  <validation-observer
    ref="memberSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="memberSaveModal"
      body-class="position-static"
      centered
      no-close-on-backdrop
      :title="isCreated ? 'Thêm thành viên' : 'Cập nhật thành viên'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="employeeIds">
              <template v-slot:label>
                Thành viên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thành viên"
                rules="required"
              >
                <v-select
                  id="employeeIds"
                  v-model="employeeIds"
                  label="name"
                  multiple
                  :options="employees"
                  :reduce="option => option.id"
                  :disabled="!isCreated"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-for="roleId">
              <template v-slot:label>
                Vai trò <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Vai trò"
                rules="required"
              >
                <v-select
                  id="roleId"
                  v-model="targetMember.factResearchArticleRoleId"
                  label="name"
                  :options="roles"
                  :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-if="isCreated && creatable"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-if="creatable || updatable"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('memberSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'ArticleMemberSave',
  components: {
    BCol,
    BRow,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    researchArticleId: {
      type: Number,
      default: null,
    },
    member: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetMember: {
        id: null,
        employeeId: null,
        researchArticleId: null,
        factResearchArticleRoleId: null,
        status: 1,
      },
      employeeIds: [],
      required,
    }
  },
  computed: {
    ...mapGetters({
      employees: 'researchArticleMember/employees',
      roles: 'factResearchArticleRole/dataSources',
    }),
    isCreated() {
      return !this.member
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RESEARCH_ARTICLE_MEMBER)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.RESEARCH_ARTICLE_MEMBER)
    },
  },
  methods: {
    ...mapActions({
      getEmployeesByOrganizationId: 'researchArticleMember/getEmployeesByOrganizationId',
      createMember: 'researchArticleMember/createArticleMember',
      updateMember: 'researchArticleMember/updateArticleMember',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.member) {
        this.targetMember = { ...this.member }
        this.employeeIds.push(this.targetMember.employeeId)
      } else {
        this.targetMember = { ...this.targetMember, researchArticleId: this.researchArticleId }
      }
      this.isLoading = true
      try {
        await this.getEmployeesByOrganizationId()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .memberSaveFormRef
        .reset()
      this.targetMember = {
        id: null,
        employeeId: null,
        researchArticleId: null,
        factResearchArticleRoleId: null,
        status: 1,
      }
      this.employeeIds = []
    },
    async onSave(type = null) {
      const valid = this.$refs
        .memberSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          let response
          if (this.isCreated) {
            const body = []
            this.employeeIds.forEach(employeeId => {
              const member = { ...this.targetMember }
              member.employeeId = employeeId
              body.push(member)
            })
            response = await this.createMember(body)
          } else {
            response = await this.updateMember(this.targetMember)
          }
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('memberSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
